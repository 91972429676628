import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import cls from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import { navigate } from 'gatsby';

import { setPage, setIsMobile } from '../store/app';
import Section from '../components/shared/section';
import Heading from '../components/shared/heading';
import t from '../locales';
import Flex from '../components/shared/flex';
import { sendMail } from '../utils/mail';
import { captcha } from '../locales/resources/captcha';

const Register = ({ setPage, isMobile }) => {
  const recaptchaRef = React.createRef();
  const [error, setError] = useState(undefined);
  useEffect(() => {
    setPage('register');
  }, [setPage]);

  const formInitVals = {
    farmName: '',
    ownerMail: '',
    farmMail: '',
    addressWww: '',
    phone: '',
    prefix: '+48',
    address: '',
    city: '',
    postCode: '',
    description: '',
    ownerCheckbox: false,
    adultCheckbox: false,
  };

  const validateForm = values => {
    let errors = {};
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    const numbersRegex = /^[1-9]{1}[0-9]{8}$/; // eslint-disable-line
    const postCodeRegex = /^[0-9]{2}-[0-9]{3}$/; // eslint-disable-line

    // eslint-disable-next-line array-callback-return
    Object.entries(values).map(([key, val]) => {
      if (!val) errors[`${key}`] = t('forms.errors.required');
      else {
        if (key === 'phone' && !numbersRegex.test(val)) errors[`${key}`] = t('forms.errors.phone');
        if (key === 'postCode' && !postCodeRegex.test(val)) errors[`${key}`] = t('forms.errors.postCode');
        if ((key === 'ownerMail' || key === 'farmMail') && !emailRegex.test(val))
          errors[`${key}`] = t('forms.errors.email');
      }
    });
    return errors;
  };

  const onSubmit = async (values, actions) => {
    let isError = false;
    const token = await recaptchaRef.current.executeAsync();
    try {
      const result = await sendMail({
        token: token,
        to: process.env.GATSBY_EMAIL_REGISTER_STABLE,
        from: 'kontakt@horsemanago.com',
        subject: `[HM] Formularz Rejestracji Ośrodka: ${values.farmName}`,
        content: formValuesToContent(values),
      });
      if (!result.success) {
        isError = true;
      } else {
        if (typeof window !== 'undefined') navigate(`/register-success`, { state: { successSend: true } });
      }
    } catch (e) {
      console.log(e.response.data); //eslint-disable-line no-console
      isError = true;
    } finally {
      actions.setSubmitting(false);
    }
    setError(isError);
  };

  const formValuesToContent = values => {
    return `<h3>Nazwa ośrodka jeździeckiego</h3>
      <p>${values.farmName}</p>
      <h3>Mail właściciela ośrodka jeździeckiego</h3>
      <p>${values.ownerMail}</p>
      <h3>Mail kontaktowy ośrodka jeździeckiego</h3>
      <p>${values.farmMail}</p>
      <h3>Adres www lub fanpage na Facebooku</h3>
      <p>${values.addressWww}</p>
      <h3>Numer telefonu</h3>
      <p>${values.prefix} ${values.phone}</p>
      <h3>Adres ośrodka jeździeckiego</h3>
      <p>${values.address}</p>
      <h3>Miasto</h3>
      <p>${values.city}</p>
      <h3>Kod pocztowy</h3>
      <p>${values.postCode}</p>
      <h3>Opis ośrodka/działalności</h3>
      <p>${values.description}</p>`;
  };

  return (
    <>
      <Heading inverted> {t('title.register')} </Heading>
      <Section>
        <div className="text-center">
          {t('register.headerOne')}
          <span className="font-bold">{t('general.hm')}</span>.
        </div>
        <div className="text-center">{t('register.headerTwo')}</div>
        <Flex>
          <Formik
            initialValues={formInitVals}
            onSubmit={(values, actions) => onSubmit(values, actions)}
            validate={validateForm}
          >
            {({ errors, touched, isSubmitting, isValid, dirty, setFieldValue, setFieldTouched }) => {
              return (
                <Form className="farm-register">
                  <div className="form-field">
                    <label htmlFor="farmName">{t('forms.labels.farmName')}</label>
                    <Field
                      name="farmName"
                      type="text"
                      placeholder={t('forms.placeholders.farmName')}
                      className={cls(errors.farmName && touched.farmName && 'error')}
                    />
                    {errors.farmName && touched.farmName && <div className="form-error">{errors.farmName}</div>}
                  </div>
                  <div className="form-field">
                    <label htmlFor="ownerMail">{t('forms.labels.ownerMail')}</label>
                    <Field
                      name="ownerMail"
                      type="email"
                      placeholder={t('forms.placeholders.ownerMail')}
                      className={cls(errors.ownerMail && touched.ownerMail && 'error')}
                    />
                    {errors.ownerMail && touched.ownerMail && <div className="form-error">{errors.ownerMail}</div>}
                  </div>
                  <div className="form-field">
                    <label htmlFor="farmMail">{t('forms.labels.farmMail')}</label>
                    <Field
                      name="farmMail"
                      type="email"
                      placeholder={t('forms.placeholders.farmMail')}
                      className={cls(errors.farmMail && touched.farmMail && 'error')}
                    />
                    {errors.farmMail && touched.farmMail && <div className="form-error">{errors.farmMail}</div>}
                  </div>
                  <div className="form-field">
                    <label htmlFor="addressWww">{t('forms.labels.addressWww')}</label>
                    <Field
                      name="addressWww"
                      type="text"
                      placeholder={t('forms.placeholders.addressWww')}
                      className={cls(errors.addressWww && touched.addressWww && 'error')}
                    />
                    {errors.addressWww && touched.addressWww && <div className="form-error">{errors.addressWww}</div>}
                  </div>
                  <div className="form-field">
                    <Flex>
                      <div className="pr-5" style={{ width: '75px' }}>
                        <label htmlFor="prefix">{t('forms.labels.prefix')}</label>
                        <Field name="prefix" type="text" placeholder={t('forms.placeholders.prefix')} disabled={true} />
                      </div>
                      <div style={{ width: 'calc(100% - 75px)' }}>
                        <label htmlFor="phone">{t('forms.labels.phone')}</label>
                        <Field
                          name="phone"
                          type="text"
                          placeholder={t('forms.placeholders.phone')}
                          className={cls(errors.phone && touched.phone && 'error')}
                        />
                      </div>
                    </Flex>
                    {errors.phone && touched.phone && <div className="form-error w-full">{errors.phone}</div>}
                  </div>
                  <div className="form-field">
                    <label htmlFor="address">{t('forms.labels.address')}</label>
                    <Field
                      name="address"
                      type="text"
                      placeholder={t('forms.placeholders.address')}
                      className={cls(errors.address && touched.address && 'error')}
                    />
                    {errors.address && touched.address && <div className="form-error">{errors.address}</div>}
                  </div>
                  <Flex column={isMobile}>
                    <div className={cls(isMobile ? 'w-full' : 'w-3/4 pr-5')}>
                      <div className="form-field ">
                        <label htmlFor="city">{t('forms.labels.city')}</label>
                        <Field
                          name="city"
                          type="text"
                          placeholder={t('forms.placeholders.city')}
                          className={cls(errors.city && touched.city && 'error')}
                        />
                        {errors.city && touched.city && <div className="form-error">{errors.city}</div>}
                      </div>
                    </div>
                    <div className={cls(isMobile ? 'w-full' : 'w-1/4')}>
                      <div className="form-field">
                        <label htmlFor="postCode">{t('forms.labels.postCode')}</label>
                        <Field
                          name="postCode"
                          type="text"
                          placeholder={t('forms.placeholders.postCode')}
                          className={cls(errors.postCode && touched.postCode && 'error')}
                        />
                        {errors.postCode && touched.postCode && <div className="form-error">{errors.postCode}</div>}
                      </div>
                    </div>
                  </Flex>
                  <div className="form-field">
                    <label htmlFor="description">{t('forms.labels.description')}</label>
                    <Field
                      name="description"
                      component="textarea"
                      rows="4"
                      placeholder={t('forms.placeholders.description')}
                      className={cls(errors.description && touched.description && 'error')}
                    />
                    {errors.description && touched.description && (
                      <div className="form-error">{errors.description}</div>
                    )}
                  </div>
                  <div className="form-field">
                    <Flex className="checkbox-flex">
                      <Field
                        name="ownerCheckbox"
                        render={({ field }) => (
                          <div
                            className={cls('checkbox-wrapper', {
                              'bg-teal hover:bg-teal-dark': field.value,
                              error: errors.ownerCheckbox && touched.ownerCheckbox,
                            })}
                          >
                            <input
                              id="ownerCheckbox"
                              {...field}
                              type="checkbox"
                              checked={field.value}
                              onClick={() => {
                                setFieldValue('ownerCheckbox', !field.value);
                                setFieldTouched('ownerCheckbox', true, true);
                              }}
                            />
                            {field.value && <i className="checkbox-check-icon hm-ok small white" />}
                          </div>
                        )}
                      />
                      <label htmlFor="ownerCheckbox">{t('forms.labels.ownerCheckbox')}</label>
                    </Flex>
                    {errors.ownerCheckbox && touched.ownerCheckbox && (
                      <div className="form-error">{errors.ownerCheckbox}</div>
                    )}
                  </div>
                  <div className="form-field">
                    <Flex className="checkbox-flex">
                      <Field
                        name="adultCheckbox"
                        render={({ field }) => (
                          <div
                            className={cls('checkbox-wrapper', {
                              'bg-teal hover:bg-teal-dark': field.value,
                              error: errors.adultCheckbox && touched.adultCheckbox,
                            })}
                          >
                            <input
                              id="adultCheckbox"
                              {...field}
                              type="checkbox"
                              checked={field.value}
                              onClick={() => {
                                setFieldValue('adultCheckbox', !field.value);
                                setFieldTouched('adultCheckbox', true, true);
                              }}
                            />
                            {field.value && <i className="checkbox-check-icon hm-ok small white" />}
                          </div>
                        )}
                      />
                      <label htmlFor="adultCheckbox">{t('forms.labels.adultCheckbox')}</label>
                    </Flex>
                    {errors.adultCheckbox && touched.adultCheckbox && (
                      <div className="form-error">{errors.adultCheckbox}</div>
                    )}
                  </div>
                  <div className="form-field">
                    <p class="text-xs">
                      <span>
                        {t('register.gdpr')}{' '}
                        <a href="/pl/privacy-policy/" target="_blank" className="color-link">
                          {t('general.here')}
                        </a>
                        .
                      </span>
                    </p>
                  </div>
                  <div className="form-field">
                    <label htmlFor="captcha">
                      <div className="field-content">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          size="invisible"
                          sitekey={process.env.GATSBY_RECAPTCHA_SITEKEY}
                          theme="dark"
                        />
                      </div>
                    </label>
                  </div>
                  <div className="form-field">{captcha.pl}</div>
                  {error !== undefined && error && <div className="alert error">{t('forms.messages.error')}</div>}
                  <div>
                    <button
                      disabled={!(isValid && dirty) || isSubmitting}
                      className={cls('button teal', { 'w-full': isMobile })}
                      type="submit"
                    >
                      {isSubmitting ? t('general.sending') : t('forms.actions.send')}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Flex>
      </Section>
    </>
  );
};

export default connect(
  state => ({
    pageName: state.app.pageName,
    isMobile: state.app.isMobile,
  }),
  dispatch => ({
    setPage: pageName => dispatch(setPage(pageName)),
    setIsMobile: isMobile => dispatch(setIsMobile(isMobile)),
  })
)(Register);
