import React from 'react';
import t from '../';

const captchaPl = (
  <p className="text-xs">
    {t('forms.captcha.protected')}
    <a className="color-link" href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
      {t('forms.captcha.privacy')}
    </a>
    {t('forms.captcha.and')}
    <a className="color-link" href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
      {t('forms.captcha.terms')}
    </a>
    .
  </p>
);

const captchaEn = (
  <p className="text-xs">
    {t('forms.captcha.protected')}
    <a className="color-link" href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
      {t('forms.captcha.privacy')}
    </a>
    {t('forms.captcha.and')}
    <a className="color-link" href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
      {t('forms.captcha.terms')}
    </a>{' '}
    apply.
  </p>
);

export const captcha = {
  pl: captchaPl,
  en: captchaEn,
};
