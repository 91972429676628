import axios from 'axios';

export const sendMail = async ({ token, from, subject, content, to = false }) => {
  if (!token || !from || !subject || !content) {
    return { success: false };
  }

  const res = await axios.post('https://vms.vasco-technologies.com:3000/email/send', {
    token,
    to: to || process.env.GATSBY_EMAIL,
    from,
    subject,
    html: content,
  });

  return res?.data;
};
